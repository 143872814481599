.grid-delete {
    right: 5px;
    bottom: 15px;
    cursor: pointer;

    &:hover {
        color: #E31D19;
    }
}

.grid-email {
    right: 5px;
    bottom: 15px;
    cursor: pointer;

    &:hover {
        color: #0054ff;
    }
}

.grid-header {
    color: white;
    background-color: #284F9E;
}

.grid-btn-export-csv {
    color: white;
    background-color: #284F9E;

    &:hover {
        color: white;
        background-color: #339DD3;
    }

    &:active {
        color: white;
        background-color: #284F9E;
    }
}

.grid-btn-export {
    color: white;
    background-color: #002d89;

    &:hover {
        color: white;
        background-color: #0049de;
    }
}